// project import
import analytics from './analytics';
// types
import { NavItemType } from 'types/menu';
import users from './users';
import collaborators from './collaborators';
import settings from './settings';
import referralCodes from './referralCodes';
import faq from './faq';
import contracts from './platformContracts';
import partners from './partners';
import log from './log';
import support from './support';
// import autopilot from './autopilot';
// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [analytics, users, collaborators, partners, contracts, referralCodes, settings, log, faq, support]
};

export default menuItems;
