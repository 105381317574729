import { useFormik } from 'formik';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { VariablesPlugin } from 'components/custom/TextEditor/VariablesPlugin';
import { useState } from 'react';
import { Box, Button } from '@mui/material';

// import { getTypeByOccurrency, getVerticalGrid } from 'sections/autopilot/components/flux/nodeBuilder';
// import { Node } from 'types/dto/autopilot.dto';

let editorInstance: any;
export const Prova = () => {
  const [showPlugin, setShowPlugin] = useState<boolean>(false);
  const text = useFormik<{ content: string }>({
    initialValues: {
      content: ''
    },
    onSubmit: (values) => {
      console.log('transformed', substituteVariables(values.content));
    }
  });

  const substituteVariables = (html: string): string => {
    const pattern = /custom-prop-(.*?")/g;
    const match = html.match(pattern);
    if (match) {
      const customProp = match[0].substring(12, match[0].length - 1);
      html = html.replaceAll(match[0], `" simoProp="${customProp}"`);
    }
    if (html.includes('custom-prop')) {
      return substituteVariables(html);
    } else return html;
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        {showPlugin && <VariablesPlugin core={editorInstance.core} />}

        <SunEditor
          lang={'it'}
          setContents={text.values.content}
          getSunEditorInstance={(editor) => {
            editorInstance = editor;
            setShowPlugin(true);
          }}
          onChange={(content) => {
            text.setFieldValue('content', content);
          }}
          setOptions={{
            font: ['WorkSans'],
            buttonList: [
              ['undo', 'redo'],
              ['fontSize'],
              ['paragraphStyle', 'blockquote'],
              ['bold', 'underline', 'italic'],
              ['fontColor'],
              ['align', 'list'],
              ['outdent', 'indent'],
              ['link'],
              ['removeFormat']
            ],
            defaultTag: 'div',
            minHeight: '300px',
            showPathLabel: false
          }}
        />
        <Box className="flex just-end">
          <Button variant="contained" onClick={() => text.handleSubmit()}>
            salva
          </Button>
        </Box>
      </div>
    </>
  );
  // const nodes: Node[] = [
  //   { id: 0, next: [1, 2], previous: [], type: 'event', title: 'E1' },
  //   { id: 1, next: [4], previous: [0], type: 'filter', title: 'F1' },
  //   { id: 2, next: [3], previous: [0], type: 'filter', title: 'F2' },
  //   { id: 3, next: [4], previous: [2], type: 'filter', title: 'F3' },
  //   { id: 4, next: [5], previous: [1, 3], type: 'action', title: 'A1' },
  //   { id: 5, next: [], previous: [4], type: 'filter', title: 'F4' }
  // ] as any;

  // console.log('testo per getvertical', getVerticalGrid(getTypeByOccurrency(nodes, 'filter')));

  // return <></>;
};
