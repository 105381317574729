import { Box, Button, Menu, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { BuildingDTO } from 'types/dto/building.dto';
import { OrderDTO } from 'types/dto/order.dto';
import { UserDTO } from 'types/dto/user.dto';
import { VariableSearch } from './VariableSearch';
import { translateCategory } from 'utils/translatePath';

interface Props {
  core: any;
}

interface FakeData {
  user: Partial<UserDTO>;
  building: Partial<BuildingDTO>;
  order: Partial<OrderDTO>;
}
const fakeData: FakeData = {
  user: {
    firstname: 'Nome',
    lastname: 'Cognome',
    phone: 'N° di telefono'
  },
  building: {
    name: 'Nome',
    businessName: 'Denominazione societaria'
  },
  order: {
    code: 'Codice della pratica',
    title: 'Titolo'
  }
};

export const VariablesPlugin = ({ core }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddVariable = ({ serverProp, label }: { serverProp: string; label: string }) => {
    const node = core.util.createElement('span');
    const space = core.util.createElement('span');
    space.textContent = '\xa0';
    core.util.addClass(node, `se-custom-variable custom-prop-${serverProp}`);
    node.textContent = label;

    core.insertNode(node);
    core.insertNode(space, node);
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: 5,
          borderRadius: 1,
          paddingX: 2,
          border: '1px solid #DADADA',
          backgroundColor: '#fafafa',
          left: 626,
          height: '38px',
          zIndex: 3000,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'rgb(240,240,240)'
          }
        }}
      >
        <Typography variant="caption" sx={{ color: '#000000', fontFamily: 'arial', fontSize: '12px' }}>
          {'Inserisci variabile'}
        </Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: 3000,
          '.MuiPaper-root': { background: 'white', minWidth: '200px', paddingX: 4, boxShadow: '-1px 2px 2px rgba(0,0,0,.2)' }
        }}
      >
        <Box width="100%" className="flex just-btwn">
          {Object.keys(fakeData).map((cat, i) => (
            <Box key={`category-${i}`} width={200} mx={1} mb={3}>
              <Typography>{translateCategory(cat)}</Typography>
              <VariableSearch endpoint={cat} addVariable={(newVar: { serverProp: string; label: string }) => handleAddVariable(newVar)} />
            </Box>
          ))}
        </Box>
        <Button
          onClick={() => {
            handleAddVariable({ serverProp: '$.user.firstname', label: 'Utente | Nome' });
          }}
        >
          aggiungi variabile nome utente
        </Button>
        <Button
          onClick={() => {
            handleAddVariable({ serverProp: '$.user.lastname', label: 'Utente | Cognome' });
          }}
        >
          aggiungi variabile cognome utente
        </Button>
        <Button
          onClick={() => {
            handleAddVariable({ serverProp: '$.order.code', label: 'Ordine | Codice identificativo' });
          }}
        >
          aggiungi variabile codice ordine
        </Button>
      </Menu>
    </>
  );
};
