import { Checkbox, ClickAwayListener, TextField, TooltipProps } from '@mui/material';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Calendar } from 'react-date-range';
//@ts-ignore
import * as rdrLocales from 'react-date-range/dist/locale';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { FormikErrors } from 'formik';
import { CalendarTooltip } from './CalendarTooltip';

interface Props {
  label: string;
  formikField: string;
  ISODate?: string | null;
  setISODate: (formikField: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<any>>;
  isConfirmed?: boolean;
  py?: number;
  mt?: number;
  width?: number | string;
  popperPlacement?: TooltipProps['placement'];
  minDate?: Date;
  placeholder?: string;
}
const DateSelector = ({
  label,
  formikField,
  ISODate,
  setISODate,
  isConfirmed,
  py,
  mt,
  width,
  popperPlacement,
  minDate,
  placeholder
}: Props) => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [expiration, setExpiration] = useState<string>();

  const openDatePicker = () => {
    setShowDatePicker(true);
  };

  const closeDatePicker = () => {
    setShowDatePicker(false);
  };

  const handleSetDate = (date: Date) => {
    setExpiration(dayjs(date).add(2, 'hours').format('DD/MM/YYYY'));
    setISODate(formikField, dayjs(date).add(2, 'hours').toISOString());
  };

  return (
    <Box className="flex dir-col" py={py ?? 0} position="relative" width={width ?? 180}>
      {!ISODate && !showDatePicker && (
        <TextField
          className={isConfirmed ? 'disabled-input' : ''}
          sx={{
            input: {
              cursor: 'pointer',
              caretColor: 'transparent'
            },
            mt: mt ?? 0,
            width: width,
            '.MuiInputBase-root': { pl: isConfirmed ? 0 : 2 }
          }}
          size="small"
          label={label}
          placeholder="gg/mm/aaaa"
          InputProps={{
            sx: { cursor: 'pointer' },
            startAdornment:
              isConfirmed === undefined ? (
                <></>
              ) : isConfirmed ? (
                <Checkbox checked={isConfirmed} disabled={isConfirmed ? true : false} />
              ) : (
                <></>
              ),
            endAdornment: showDatePicker ? <ArrowDropUp className="textSecondary" /> : <ArrowDropDown className="textSecondary" />
          }}
          value={placeholder ?? 'Caricamento nuova versione'}
          onClick={openDatePicker}
        />
      )}
      {showDatePicker && ISODate === undefined && (
        <CalendarTooltip
          placement={popperPlacement ?? 'bottom'}
          open={showDatePicker}
          title={
            <ClickAwayListener onClickAway={closeDatePicker}>
              <Box width="100%" height="100%">
                <Calendar locale={rdrLocales.it} minDate={minDate} date={ISODate} onChange={(date: Date) => handleSetDate(date)} />
              </Box>
            </ClickAwayListener>
          }
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <TextField
            className={isConfirmed ? 'disabled-input' : 'clickable'}
            sx={{ input: { cursor: 'pointer' }, mt: mt ?? 2, width: width, '.MuiInputBase-root': { pl: isConfirmed ? 0 : 2 } }}
            size="small"
            label={label}
            placeholder="gg/mm/aaaa"
            InputProps={{
              startAdornment:
                isConfirmed === undefined ? <></> : isConfirmed ? <Checkbox checked={isConfirmed} disabled={isConfirmed} /> : <></>,
              endAdornment: showDatePicker ? <ArrowDropUp className="textSecondary" /> : <ArrowDropDown className="textSecondary" />
            }}
            value={'Scegli una data'}
            onClick={openDatePicker}
          />
        </CalendarTooltip>
      )}
      {ISODate && (
        <CalendarTooltip
          placement={popperPlacement ?? 'bottom'}
          open={showDatePicker}
          title={
            <ClickAwayListener onClickAway={closeDatePicker}>
              <Box width="100%" height="100%">
                <Calendar
                  locale={rdrLocales.it}
                  minDate={minDate}
                  date={typeof ISODate === 'string' ? dayjs(ISODate).toDate() : ISODate}
                  onChange={(date: Date) => handleSetDate(date)}
                />
              </Box>
            </ClickAwayListener>
          }
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <TextField
            className={isConfirmed ? 'disabled-input' : 'clickable'}
            sx={{
              input: {
                cursor: 'pointer',
                caretColor: 'transparent'
              },
              mt: mt ?? 2,
              width: width,
              '.MuiInputBase-root': { pl: isConfirmed ? 0 : 2 }
            }}
            size="small"
            label={label}
            placeholder="gg/mm/aaaa"
            InputProps={{
              sx: { cursor: 'pointer' },
              startAdornment:
                isConfirmed === undefined ? (
                  <></>
                ) : isConfirmed ? (
                  <Checkbox checked={isConfirmed} disabled={isConfirmed ? true : false} />
                ) : (
                  <></>
                ),
              endAdornment: showDatePicker ? <ArrowDropUp className="textSecondary" /> : <ArrowDropDown className="textSecondary" />
            }}
            value={expiration ? expiration : dayjs(ISODate).format('DD/MM/YYYY')}
            onClick={openDatePicker}
          />
        </CalendarTooltip>
      )}
    </Box>
  );
};

export default DateSelector;
