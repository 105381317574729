import { UserDTO } from 'types/dto/user.dto';
import AsyncAutoComplete from '../AsyncAutocomplete/AsyncAutocomplete';
import { BuildingDTO } from 'types/dto/building.dto';
import { OrderDTO } from 'types/dto/order.dto';
import { Box, TextField } from '@mui/material';
import { useState } from 'react';

interface Props {
  endpoint: string;
  addVariable: ({ serverProp, label }: { serverProp: string; label: string }) => void;
}
export const VariableSearch = ({ endpoint, addVariable }: Props) => {
  const [variable, setVariable] = useState<{ serverProp: string; label: string } | null>(null);
  return (
    <AsyncAutoComplete<UserDTO & BuildingDTO & OrderDTO>
      endpoint={endpoint}
      getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name}`)}
      id={'async-subjects'}
      fullWidth
      size="small"
      disabled
      value={variable?.label ? variable.label : null}
      renderInput={({ InputLabelProps, ...params }) => <TextField {...params} label="Cerca variabile" value={'Cerca variabile'} />}
      ListboxProps={{ style: { maxHeight: 270, maxWidth: 210, backgroundColor: '#ffffff', position: 'absolute', right: -200, top: 40 } }}
      renderOption={(props, option) => (
        <li {...props} key={endpoint === '/orders' ? option.code : option.id}>
          <Box
            sx={{ paddingX: 1, paddingY: 0.3 }}
            onClick={(e) => {
              addVariable({
                serverProp: option.name,
                label: option.name
              });
              setVariable(null);
            }}
          >
            {option.name}
          </Box>
        </li>
      )}
      onChange={(item, serverOptions, setOptions, e) => {
        const selectedEl = item as (UserDTO & BuildingDTO & OrderDTO) | null;
        if (selectedEl === null) {
          setVariable({ serverProp: '', label: '' });
          return;
        }
        const subject = {
          label: selectedEl.name,
          id: selectedEl.name
        };

        setOptions(serverOptions.filter((o) => o.name !== subject.label));
      }}
    />
  );
};
